import {
  applyPolyfills,
  defineCustomElements as defineInputElements,
} from '@ispui/input/loader';
import { defineCustomElements as defineButtonElements } from '@ispui/button/loader';
import { defineCustomElements as defineDropdownElements } from '@ispui/dropdown/loader';

import { LoginPage } from './login-form';

applyPolyfills().then(() => {
  defineInputElements();
  defineButtonElements();
  defineDropdownElements();
});

document.addEventListener('DOMContentLoaded', function () {
  const loginPage = new LoginPage();

  if (loginPage.inputLang) {
    loginPage.inputLang.addEventListener('change', function (e) {
      loginPage.selectHandler(e, { id: loginPage.inputLang.id });
    });
  }

  if (loginPage.form) {
    loginPage.form.addEventListener('submit', function (e) {
      loginPage.submitLoginForm(e);
    });
  }
});
